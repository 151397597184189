.home {
  color: #0c476f;
  background: rgba(0, 0, 0, 0) linear-gradient(to right, #fff 40%, #c7d6eb 100%) repeat scroll 0 0;
  width: 100%; }
  .home img {
    width: 250px; }
  .home .header-fill {
    background: rgba(0, 0, 0, 0) linear-gradient(to right, #fff 40%, #c7d6eb 100%) repeat scroll 0 0;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.8); }
  .home .home-header {
    width: calc(100% - 200px);
    position: fixed;
    padding: 40px 50px 18px 150px;
    display: flex;
    flex-direction: row;
    z-index: 10; }
    .home .home-header .header-bars {
      display: none; }
    .home .home-header .header-logo {
      width: 60%; }
    .home .home-header .header-tabs {
      width: 50%;
      display: flex;
      flex-direction: row;
      font-size: 23px;
      float: right; }
      .home .home-header .header-tabs a {
        color: #0c476f;
        text-decoration: none;
        text-align: center;
        height: 62px;
        font-size: 23px;
        padding: 0px 20px; }
      .home .home-header .header-tabs a.close {
        display: none; }
      .home .home-header .header-tabs a.Clicked {
        border-bottom: 10px solid #0c476f; }
      .home .home-header .header-tabs a:hover {
        border-bottom: 10px solid #0c476f;
        transition: all 200ms ease-out; }
  .home .main-content {
    width: 100%; }
    .home .main-content #features {
      background-color: white;
      height: 200px; }
      .home .main-content #features .features-content:after {
        background: rgba(0, 0, 0, 0) linear-gradient(to right, #1760bf 0%, #4ea7e1 100%) repeat scroll 0 0;
        position: absolute;
        content: "";
        border-radius: 100%;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.3s all ease-in-out;
        transform: scale(0.5);
        box-sizing: border-box; }
      .home .main-content #features .features-content {
        display: flex;
        flex-direction: row;
        text-align: center; }
        .home .main-content #features .features-content .feature {
          padding: 0 20px; }
        .home .main-content #features .features-content .feature:first-child {
          padding: 0 30px 0 0; }
        .home .main-content #features .features-content .feature:last-child {
          padding: 0 0 0 20px; }
        .home .main-content #features .features-content .title {
          font-size: 26px;
          padding-top: 30px; }
        .home .main-content #features .features-content .description {
          font-size: 16px;
          padding-top: 10px; }
        .home .main-content #features .features-content .icon {
          background: rgba(0, 0, 0, 0) linear-gradient(to right, #fff 20%, #c7d6eb 100%) repeat scroll 0 0;
          width: 120px;
          margin: 0 auto;
          line-height: 120px;
          border-radius: 100%;
          position: relative;
          -webkit-box-shadow: 0px 5px 30px -9px rgba(0, 0, 0, 0.35);
          -moz-box-shadow: 0px 5px 30px -9px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 5px 30px -9px rgba(0, 0, 0, 0.35);
          text-align: center;
          display: block;
          box-sizing: border-box;
          font-size: 3em;
          z-index: 0; }
          .home .main-content #features .features-content .icon i {
            font-size: 52px;
            transition: 0.3s all ease-in-out;
            position: relative;
            background: -webkit-linear-gradient(left, #1760bf 0%, #4ea7e1 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: 'themify';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            box-sizing: border-box; }
        .home .main-content #features .features-content .one {
          width: 33%; }
        .home .main-content #features .features-content .two {
          width: 33%; }
        .home .main-content #features .features-content .three {
          width: 33%; }
    .home .main-content section {
      height: 400px;
      padding: 150px 150px; }
      .home .main-content section ol, .home .main-content section ul {
        margin-top: 20px;
        font-size: 18px;
        text-align: left;
        padding-left: 37px; }
        .home .main-content section ol li, .home .main-content section ul li {
          padding: 0 0 0 10px; }
      .home .main-content section ol {
        padding-bottom: 0px;
        margin-bottom: 0px;
        list-style: none;
        counter-reset: li; }
        .home .main-content section ol li::before {
          content: counter(li);
          color: #0c476f;
          display: inline-block;
          width: 1em;
          margin-left: -1em; }
        .home .main-content section ol li {
          counter-increment: li; }
      .home .main-content section ul {
        margin-left: -20px;
        margin-top: 0px; }
      .home .main-content section h2 {
        font-size: 30px; }
      .home .main-content section .five-steps {
        margin-top: 20px;
        font-size: 26px;
        text-transform: none; }
      .home .main-content section .app-image {
        padding-top: 50px; }
      .home .main-content section .app-image.mobile {
        display: none; }
    .home .main-content #who {
      padding-top: 200px;
      background-color: #0c476f;
      color: white;
      display: flex;
      flex-direction: row; }
      .home .main-content #who .who-content {
        width: 50%; }
        .home .main-content #who .who-content h1.main-title {
          text-align: left;
          font-size: 36px;
          margin-top: 0px;
          font-weight: 300;
          padding-bottom: 20px;
          border-bottom: 1px solid #000;
          font-style: normal;
          letter-spacing: 10px;
          left: 0;
          right: 0;
          margin: auto;
          line-height: 1.1; }
        .home .main-content #who .who-content .section-content {
          padding-top: 30px;
          line-height: 20px;
          font-weight: 500; }
          .home .main-content #who .who-content .section-content .tagline {
            width: 100%;
            font-size: 22px;
            padding-bottom: 20px;
            line-height: 30px; }
      .home .main-content #who .who-image {
        width: 50%; }
        .home .main-content #who .who-image img {
          padding: 0 10%;
          display: block; }
    .home .main-content #how {
      padding-top: 200px;
      background-color: transparent; }
      .home .main-content #how .how-content {
        display: flex;
        flex-direction: row;
        text-align: left; }
        .home .main-content #how .how-content h1 {
          text-align: left;
          font-size: 36px;
          margin-top: 0px;
          font-weight: 300;
          padding-bottom: 20px;
          border-bottom: 1px solid #000;
          font-style: normal;
          letter-spacing: 10px;
          left: 0;
          right: 0;
          margin: auto;
          line-height: 1.1; }
        .home .main-content #how .how-content div:first-child {
          width: 65%; }
        .home .main-content #how .how-content div:last-child {
          width: 45%; }
        .home .main-content #how .how-content .app-image {
          padding-left: 185px;
          padding-top: 0px; }
    .home .main-content .section-value {
      padding-top: 150px;
      padding-bottom: 200px; }
      .home .main-content .section-value h2 {
        color: #E7BE48;
        line-height: 53px;
        border-bottom: 1px solid;
        font-size: 40px;
        width: 130px;
        margin: auto; }
      .home .main-content .section-value .value-columns {
        display: flex;
        flex-direction: row;
        color: #0c476f; }
        .home .main-content .section-value .value-columns .value-content {
          margin-top: 150px;
          text-align: left; }
        .home .main-content .section-value .value-columns .app-image img {
          margin: 0 40%;
          display: block; }
        .home .main-content .section-value .value-columns h4 {
          font-size: 52px;
          line-height: 60px; }
        .home .main-content .section-value .value-columns div {
          width: 50%; }
    .home .main-content .section-download {
      background-color: #c1272d;
      color: white;
      padding-top: 200px;
      text-align: center;
      font-size: 16px; }
      .home .main-content .section-download a {
        color: white;
        font-size: 20px; }
    .home .main-content .section-contact {
      background-color: #0c476f; }
    .home .main-content .section-copyright {
      text-align: center;
      background-color: white;
      height: 50px;
      color: black;
      padding: 0px; }
      .home .main-content .section-copyright .copyright {
        padding: 50px 0; }

@media all and (max-width: 812px) and (max-height: 375px) {
  .home-header {
    padding: 20px !important;
    width: calc(100% - 40px) !important; }
    .home-header .header-logo {
      width: 52% !important; }
    .home-header .header-tabs {
      width: 70% !important;
      padding-left: 0px !important; }
      .home-header .header-tabs a {
        height: 43px !important;
        padding: 0px 10px;
        border-bottom: 10px solid transparent; }
  .home .main-content #how {
    padding: 130px 50px !important; }
  .home .main-content #how .how-content div:first-child {
    width: 64%; }
  .home .main-content #how .how-content div:last-child {
    width: 28%;
    margin-left: 20px;
    padding-left: 0px; }
  .home .main-content #who {
    padding: 130px 50px !important; }
  .home .main-content #who .who-content {
    width: 56%; }
  .home .main-content #who .who-image {
    width: 44%; } }

@media all and (max-width: 736px) and (max-height: 414px) {
  .home-header {
    padding: 20px !important;
    width: calc(100% - 40px) !important; }
    .home-header .header-logo {
      width: 52% !important; }
    .home-header .header-tabs {
      width: 64% !important;
      padding-left: 0px !important; }
      .home-header .header-tabs a {
        height: 43px !important;
        padding: 0px 10px;
        border-bottom: 10px solid transparent; }
  .home .main-content #how {
    padding: 130px 50px !important; }
  .home .main-content #how .how-content div:first-child {
    width: 64% !important; }
  .home .main-content #how .how-content div:last-child {
    width: 28%;
    margin-left: 20px;
    padding-left: 0px; }
  .home .main-content #who {
    padding: 130px 50px !important; }
  .home .main-content #who .who-content {
    width: 56%; }
  .home .main-content #who .who-image {
    width: 37% !important; } }

@media all and (max-width: 667px) and (max-height: 375px) {
  .home-header {
    padding: 20px !important;
    width: calc(100% - 40px) !important; }
    .home-header .header-logo {
      width: 64% !important; }
      .home-header .header-logo img {
        width: 200px; }
    .home-header .header-tabs {
      width: 64% !important;
      padding-left: 0px !important; }
      .home-header .header-tabs a {
        height: 43px !important;
        padding: 0px 10px;
        border-bottom: 10px solid transparent;
        font-size: 18px !important; }
  .home .main-content #how {
    padding: 130px 50px !important; }
  .home .main-content #how .how-content div:first-child {
    width: 65% !important; }
  .home .main-content #how .how-content div:last-child {
    width: 31% !important;
    margin-left: 20px;
    padding-left: 0px; }
    .home .main-content #how .how-content div:last-child img {
      width: 200px; }
  .home .main-content #who {
    padding: 130px 30px !important; }
  .home .main-content #who .who-content {
    width: 60% !important;
    padding-left: 0px !important; }
  .home .main-content #who .who-image {
    width: 37% !important; }
    .home .main-content #who .who-image img {
      width: 200px; } }

@media all and (max-width: 568px) and (max-height: 320px) {
  .home .main-content section .app-image img {
    width: 200px; }
  .home .main-content .section-value {
    padding-top: 70px;
    padding-bottom: 70px; }
  .home .main-content .section-value .value-columns .value-content {
    margin-top: 90px !important; }
  .home .main-content .section-value .value-columns h4 {
    font-size: 30px !important;
    line-height: 32px !important; }
  .home-header {
    padding: 20px !important;
    width: calc(100% - 40px) !important; }
    .home-header .header-logo {
      width: 54% !important; }
      .home-header .header-logo img {
        width: 150px !important; }
    .home-header .header-tabs {
      width: 64% !important;
      padding-left: 0px !important; }
      .home-header .header-tabs a {
        height: 34px !important;
        padding: 0px 10px;
        border-bottom: 10px solid transparent;
        font-size: 16px !important; }
  .home .main-content #how {
    padding: 110px 50px !important; }
    .home .main-content #how h1 {
      font-size: 24px !important; }
  .home .main-content section .five-steps {
    font-size: 23px; }
  .home .main-content #how .how-content div:first-child {
    width: 65% !important; }
  .home .main-content #how .how-content div:last-child {
    width: 31% !important;
    margin-left: 20px;
    padding-left: 0px; }
    .home .main-content #how .how-content div:last-child img {
      width: 150px !important; }
  .home .main-content #who .who-content h1.main-title {
    text-align: left;
    font-size: 32px; }
  .home .main-content #who {
    padding: 110px 30px 240px !important; }
  .home .main-content #who .who-content {
    width: 62% !important;
    padding-left: 0px !important; }
  .home .main-content #who .who-image {
    width: 37% !important; }
    .home .main-content #who .who-image img {
      width: 150px !important; }
  .home .main-content #who .who-content .section-content .tagline {
    font-size: 20px;
    line-height: 26px; }
  .home .main-content .section-download {
    padding-top: 100px; } }

@media all and (max-width: 823px) and (max-height: 411px) {
  .home .main-content .section-value {
    padding-top: 100px !important; }
  .home .main-content .section-value .value-columns .value-content {
    margin-top: 114px; }
  .home .main-content .section-value .value-columns h4 {
    font-size: 40px;
    line-height: 43px; }
  .home-header {
    padding: 20px !important;
    width: calc(100% - 40px) !important; }
    .home-header .header-logo {
      width: 52% !important; }
    .home-header .header-tabs {
      width: 70% !important;
      padding-left: 0px !important; }
      .home-header .header-tabs a {
        height: 43px !important;
        padding: 0px 10px;
        border-bottom: 10px solid transparent; }
  .home .main-content #how {
    padding: 130px 50px !important; }
  .home .main-content #how .how-content div:first-child {
    width: 64%; }
  .home .main-content #how .how-content div:last-child {
    width: 28%;
    margin-left: 20px;
    padding-left: 0px; }
  .home .main-content #who {
    padding: 130px 50px !important; }
  .home .main-content #who .who-content {
    width: 56%; }
  .home .main-content #who .who-image {
    width: 44%; } }

@media all and (max-width: 1024px) {
  .home .home-header {
    padding: 40px 50px 40px 100px;
    width: calc(100% - 150px); }
    .home .home-header .header-logo {
      width: 40%; }
    .home .home-header .header-tabs {
      width: 60%;
      padding-left: 80px; }
  .home .main-content .section-value .value-columns .app-image img {
    margin: 0 25%; }
  .home .main-content section {
    padding: 100px 100px; }
  .home .main-content #who {
    padding-top: 200px;
    padding-bottom: 200px; }
  .home .main-content #features {
    padding-bottom: 200px; }
  .home .main-content #how {
    padding-bottom: 200px; } }

@media all and (max-width: 768px) {
  .home .home-header {
    padding: 40px 50px 40px 50px;
    width: 100%; }
    .home .home-header .header-logo {
      width: 40%; }
    .home .home-header .header-tabs {
      width: 60%;
      padding-left: 0px; }
      .home .home-header .header-tabs a {
        padding: 0px 10px;
        border-bottom: 10px solid transparent; }
  .home .main-content .section-value .value-columns h4 {
    font-size: 39px;
    line-height: 53px; }
  .home .main-content .section-value .value-columns .app-image img {
    margin: 0 25%; }
  .home .main-content .section-value .value-columns .value-content {
    margin-top: 260px; }
  .home .main-content section {
    padding: 100px 50px; }
  .home .main-content #who {
    padding-top: 200px;
    padding-bottom: 200px; }
    .home .main-content #who .who-image {
      width: 40%; }
      .home .main-content #who .who-image img {
        padding: 0px; }
    .home .main-content #who .who-content {
      width: 70%;
      padding-left: 60px; }
  .home .main-content #features {
    padding-bottom: 200px; }
    .home .main-content #features .features-content .title {
      line-height: 28px; }
  .home .main-content #how {
    padding-bottom: 200px;
    padding-right: 30px; }
    .home .main-content #how .how-content h1 {
      letter-spacing: 8px; }
    .home .main-content #how .how-content div:first-child {
      width: 55%; }
    .home .main-content #how .how-content div:last-child {
      width: 39%; }
    .home .main-content #how .how-content .app-image {
      padding-left: 41px; } }

@media all and (max-width: 414px) {
  .home img {
    width: 100px; }
  .home .home-header {
    padding: 0px;
    width: 100%; }
    .home .home-header .header-logo {
      width: 40%;
      padding: 40px 50px 30px 30px; }
      .home .home-header .header-logo img {
        width: 127px; }
    .home .home-header .header-tabs {
      display: none;
      width: 100%;
      padding-left: 0px; }
      .home .home-header .header-tabs .tab {
        border-bottom: 1px solid black; }
      .home .home-header .header-tabs a {
        padding: 0px;
        font-size: 20px;
        text-align: left; }
        .home .home-header .header-tabs a div {
          padding: 20px; }
      .home .home-header .header-tabs a.close {
        display: block;
        text-align: right;
        padding: 10px 20px 0 0;
        height: 0px; }
      .home .home-header .header-tabs a:hover {
        border-bottom: none; }
      .home .home-header .header-tabs a.Clicked {
        border-bottom: none; }
    .home .home-header .header-tabs.open {
      display: block;
      background-color: #0c476f;
      width: 100%;
      position: absolute;
      padding: 0px; }
      .home .home-header .header-tabs.open a {
        color: white; }
    .home .home-header .header-bars {
      display: block;
      float: right;
      width: 35%;
      text-align: right;
      padding: 40px 50px 40px 50px; }
  .home .main-content .section-value {
    padding-bottom: 10px;
    padding-top: 80px; }
    .home .main-content .section-value .value-columns h4 {
      font-size: 22px;
      line-height: 24px; }
    .home .main-content .section-value .value-columns .app-image img {
      margin: 0 25%; }
    .home .main-content .section-value .value-columns .value-content {
      margin-top: 117px; }
  .home .main-content section {
    padding: 100px 40px;
    height: 300px; }
  .home .main-content #who {
    display: flex;
    flex-direction: column;
    padding-top: 130px;
    padding-bottom: 250px; }
    .home .main-content #who .who-image {
      display: none; }
    .home .main-content #who .who-content {
      width: 100%;
      padding-left: 0px; }
      .home .main-content #who .who-content h1.main-title {
        font-size: 22px;
        text-align: center; }
  .home .main-content #features {
    height: 400px;
    padding-bottom: 120px; }
    .home .main-content #features .features-content {
      flex-direction: column;
      text-align: left; }
      .home .main-content #features .features-content .feature {
        display: flex;
        flex-direction: row;
        padding-bottom: 50px !important;
        padding-left: 0px !important; }
        .home .main-content #features .features-content .feature .icon {
          font-size: 2em;
          line-height: 101px;
          width: 116px;
          height: 100px; }
        .home .main-content #features .features-content .feature .content {
          width: 70%;
          margin-left: 30px; }
          .home .main-content #features .features-content .feature .content .title {
            padding-top: 0px; }
      .home .main-content #features .features-content .one, .home .main-content #features .features-content .two, .home .main-content #features .features-content .three {
        width: 100%; }
      .home .main-content #features .features-content .two {
        padding-bottom: 20px !important; }
  .home .main-content #how {
    padding-top: 130px;
    padding-bottom: 150px;
    padding-right: 30px;
    height: 600px; }
    .home .main-content #how .how-content {
      display: flex;
      flex-direction: column; }
      .home .main-content #how .how-content div:first-child {
        order: 2; }
      .home .main-content #how .how-content div:last-child {
        order: 1; }
      .home .main-content #how .how-content h1 {
        letter-spacing: 8px;
        font-size: 22px; }
      .home .main-content #how .how-content div:first-child {
        width: 100%;
        text-align: center; }
        .home .main-content #how .how-content div:first-child h1 {
          text-align: center; }
      .home .main-content #how .how-content div:last-child {
        width: 100%; }
      .home .main-content #how .how-content .app-image {
        display: none; }
      .home .main-content #how .how-content .app-image.mobile {
        display: block;
        padding: 40px 0 0 0; } }

@media all and (max-width: 375px) {
  .home img {
    width: 100px; }
  .home .home-header {
    padding: 0px;
    width: 100%; }
    .home .home-header .header-logo {
      width: 40%;
      padding: 40px 50px 30px 30px; }
      .home .home-header .header-logo img {
        width: 127px; }
    .home .home-header .header-tabs {
      display: none;
      width: 100%;
      padding-left: 0px; }
      .home .home-header .header-tabs .tab {
        border-bottom: 1px solid black; }
      .home .home-header .header-tabs a {
        padding: 0px;
        font-size: 20px;
        text-align: left; }
        .home .home-header .header-tabs a div {
          padding: 20px; }
      .home .home-header .header-tabs a:hover {
        border-bottom: none; }
      .home .home-header .header-tabs a.Clicked {
        border-bottom: none; }
    .home .home-header .header-tabs.open {
      display: block;
      background-color: #0c476f;
      width: 100%;
      position: absolute;
      padding: 0px; }
      .home .home-header .header-tabs.open a {
        color: white; }
    .home .home-header .header-bars {
      display: block;
      float: right;
      width: 35%;
      text-align: right;
      padding: 40px 30px 40px 50px; }
  .home .main-content .section-value {
    padding-bottom: 10px;
    padding-top: 80px; }
    .home .main-content .section-value .value-columns h4 {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 10px; }
    .home .main-content .section-value .value-columns .app-image img {
      margin: 0 25%; }
    .home .main-content .section-value .value-columns .value-content {
      margin-top: 130px; }
  .home .main-content section {
    padding: 100px 40px;
    height: 300px; }
    .home .main-content section ol {
      padding-left: 19px;
      line-height: 23px; }
  .home .main-content #who {
    display: flex;
    flex-direction: column;
    padding-top: 130px;
    padding-bottom: 300px; }
    .home .main-content #who .who-image {
      display: none; }
    .home .main-content #who .who-content {
      width: 100%;
      padding-left: 0px; }
      .home .main-content #who .who-content h1.main-title {
        font-size: 21px; }
      .home .main-content #who .who-content .section-content {
        font-size: 14px; }
        .home .main-content #who .who-content .section-content .tagline {
          font-size: 18px;
          line-height: 20px;
          font-weight: 300px; }
  .home .main-content #features {
    height: 400px;
    padding: 100px 20px 40px; }
    .home .main-content #features .features-content {
      flex-direction: column;
      text-align: left; }
      .home .main-content #features .features-content .feature:first-child {
        padding: 0px; }
      .home .main-content #features .features-content .feature {
        display: flex;
        flex-direction: row;
        padding-bottom: 50px !important;
        padding-left: 0px !important; }
        .home .main-content #features .features-content .feature .icon {
          font-size: 2em;
          line-height: 93px;
          width: 116px;
          height: 93px; }
        .home .main-content #features .features-content .feature .content {
          width: 70%;
          margin-left: 30px; }
          .home .main-content #features .features-content .feature .content .title {
            padding-top: 0px;
            font-size: 18px;
            line-height: 20px; }
          .home .main-content #features .features-content .feature .content .description {
            font-size: 14px; }
      .home .main-content #features .features-content .one, .home .main-content #features .features-content .two, .home .main-content #features .features-content .three {
        width: 100%; }
      .home .main-content #features .features-content .two {
        padding-bottom: 30px !important; }
  .home .main-content #how {
    height: 600px;
    padding: 130px 25px 100px 25px; }
    .home .main-content #how .how-content {
      display: flex;
      flex-direction: column; }
      .home .main-content #how .how-content div:first-child {
        order: 2; }
      .home .main-content #how .how-content div:last-child {
        order: 1; }
      .home .main-content #how .how-content h1 {
        letter-spacing: 8px;
        font-size: 22px; }
      .home .main-content #how .how-content div:first-child {
        width: 100%;
        text-align: center; }
        .home .main-content #how .how-content div:first-child h1 {
          text-align: center; }
      .home .main-content #how .how-content div:last-child {
        width: 100%; }
      .home .main-content #how .how-content .app-image {
        display: none; }
      .home .main-content #how .how-content .app-image.mobile {
        display: block;
        padding: 40px 0 0 0; }
  .home .main-content .section-download {
    padding-top: 130px;
    padding-bottom: 0px; } }
